import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

import { HeroVariants } from './Hero.types';

const defaultProps: ComponentsProps['Hero'] = {
  variant: HeroVariants.default
};

const styleOverrides: ComponentsOverrides<Theme>['Hero'] = {
  root: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({ ownerState, theme }),
    containerType: 'inline-size',
    position: 'relative',
    paddingTop: 'var(--section-padding)',
    paddingBottom: 'var(--section-padding)',
    // [theme.breakpoints.up('md')]: {
    //   padding: theme.spacing(10, 0)
    // },
    ...(!!ownerState?.overlap && {
      'paddingBottom': theme.spacing(0),
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(0)
      },
      '[class*=Hero-mediaWrap] > *': {
        // background: 'var(--variant-background-color, #FFF)',
        // boxShadow: theme.shadows[10]
      }
    })
  }),

  background: ({ theme, ownerState }) => ({
    ...(ownerState?.overlap
      ? {
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: theme.spacing(10),
            width: '100%',
            backgroundColor: 'var(--variant-overlay-color, #FFF)',
            zIndex: 0
          }
        }
      : {})
  }),

  // title: ({ theme }) => ({ marginBottom: theme.spacing(1) }),

  // overline: ({ theme }) => ({ marginBottom: theme.spacing(1) }),
  // title: ({ theme }) => ({}),
  // overline: ({ theme }) => ({}),

  // overline: {},

  content: ({ theme, ownerState }) => ({
    '> *:last-child': {
      marginBottom: 0
    }
  }),

  // subtitle: {},

  // body: {},

  mainContentWrap: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start'
  },

  mediaWrap: ({ theme, ownerState }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gridRow: 3,
    [theme.containerBreakpoints.up('md')]: {
      gridRow: 1,
      flexDirection: 'row'
    },
    [theme.containerBreakpoints.up('lg')]: {
      gridColumn: 1
    },
    overflow: 'hidden',
    aspectRatio: ownerState?.aspectRatio ? `${ownerState?.aspectRatio}!important` : '4/3',
    maxHeight: 'min(70vh, 720px)',
    margin: 'auto',
    // border: '1px solid red',
    img: {
      // border: '1px solid green',
      borderRadius: theme.shape.borderRadius,
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      objectPosition: 'center'
      // aspectRatio: '16/10',
      // [theme.containerBreakpoints.up('sm')]: {
      //   aspectRatio: '21/9'
      // },
      // [theme.containerBreakpoints.up('md')]: {
      //   aspectRatio: '1/1'
      // }
    }
  }),
  media: {
    objectFit: 'cover',
    height: 'auto',
    width: 'auto!important',
    // maxHeight: '80vh',
    borderRadius: 16
  },

  actionsWrap: ({ theme }) => ({
    'marginTop': theme.spacing(5),
    'display': 'flex',
    'flexDirection': 'column',
    'gap': theme.spacing(2),
    '[class*=MuiLink-root-Link-root-Hero-action]': {
      alignSelf: 'center'
    },

    [theme.containerBreakpoints.up('sm')]: {
      flexDirection: 'row',
      flexWrap: 'wrap'
      // paddingRight: theme.spacing(5)
    }
  })

  // action: {}
};

const createVariants = (theme: Theme): ComponentsVariants['Hero'] => [
  {
    props: {
      variant: HeroVariants.simpleCentered
    },
    style: {
      'textAlign': 'center',
      '[class*=Hero-mainContentWrap]': {
        'gridRow': 1,
        'gridColumnStart': 'content-start',
        'gridColumnEnd': 'content-end',

        '& *': {
          alignSelf: 'center'
        }
      },

      '[class*=Hero-mediaWrap]': {
        gridColumn: 'content-start/content-end',
        maxWidth: 800,
        margin: 'auto',
        gridRow: 2,
        img: {
          aspectRatio: '21/9',
          objectFit: 'contain'
        }
      }
    }
  },
  {
    props: {
      variant: HeroVariants.mediaOnRight
    },
    style: {
      '[class*=Hero-mainContentWrap]': {
        gridColumnStart: 'content-start',
        gridColumnEnd: 'content-end',

        [theme.containerBreakpoints.up('md')]: {
          gridColumnStart: 'content-start',
          gridColumnEnd: 'content-half'
        }
      },

      '[class*=Hero-mediaWrap]': {
        gridColumnStart: 'content-start',
        gridColumnEnd: 'content-end',

        [theme.containerBreakpoints.up('md')]: {
          gridColumnStart: 'content-half',
          gridColumnEnd: 'content-end'
        }
      }
    }
  },

  {
    props: {
      variant: HeroVariants.mediaOnLeft
    },
    style: {
      '[class*=Hero-mainContentWrap]': {
        gridColumnStart: 'content-start',
        gridColumnEnd: 'content-end',

        [theme.containerBreakpoints.up('md')]: {
          gridColumnStart: 'content-half',
          gridColumnEnd: 'content-end'
        }
      },

      '[class*=Hero-mediaWrap]': {
        gridColumnStart: 'content-start',
        gridColumnEnd: 'content-end',

        [theme.containerBreakpoints.up('md')]: {
          gridColumnEnd: 'content-half'
        }
      }
    }
  },

  {
    props: {
      variant: HeroVariants.mediaAbove
    },
    style: {
      'textAlign': 'center',
      '[class*=Hero-mainContentWrap]': {
        'gridRow': 2,
        'gridColumnStart': 'content-start',
        'gridColumnEnd': 'content-end',

        '& *': {
          alignSelf: 'center'
        }
      },

      '[class*=Hero-mediaWrap]': {
        gridColumn: 'content-start/content-end',
        maxWidth: 800,
        margin: 'auto',
        gridRow: 2,
        img: {
          aspectRatio: '21/9',
          objectFit: 'contain'
        }
      }
    }
  },
  {
    props: {
      variant: HeroVariants.mediaBelow
    },
    style: {
      'textAlign': 'center',
      '[class*=Hero-title]': {
        color: theme.vars.palette.primary.dark
      },
      '[class*=Hero-mainContentWrap]': {
        'gridRow': 1,
        'gridColumnStart': 'content-start',
        'gridColumnEnd': 'content-end',
        '& *': {
          alignSelf: 'center'
        }
      },

      '[class*=Hero-mediaWrap]': {
        gridColumn: 'content-start/content-end',
        maxWidth: 800,
        margin: 'auto',
        gridRow: 2,
        img: {
          aspectRatio: '21/9',
          objectFit: 'contain'
        }
      }
    }
  },
  {
    props: { fullBleedStyle: 'Vertical Full Bleed' },
    style: {
      'paddingTop': 0,
      'paddingBottom': 0,
      '[class*=Hero-mainContentWrap]': {
        paddingTop: 'var(--section-padding)',
        paddingBottom: 'var(--section-padding)'
      },
      '[class*=Hero-mediaWrap]': {
        img: { borderRadius: 0 }
      }
    }
  },
  {
    props: { fullBleedStyle: 'Top Full Bleed' },
    style: {
      'paddingTop': 0,
      'paddingBottom': 0,
      '[class*=Hero-mainContentWrap]': {
        paddingTop: 'var(--section-padding)',
        paddingBottom: 'var(--section-padding)'
      },
      '[class*=Hero-mediaWrap]': {
        paddingBottom: 'var(--section-padding)',
        img: { borderRadius: 0 }
      }
    }
  },
  {
    props: { fullBleedStyle: 'Bottom Full Bleed' },
    style: {
      'paddingTop': 0,
      'paddingBottom': 0,
      '[class*=Hero-mainContentWrap]': {
        paddingTop: 'var(--section-padding)',
        paddingBottom: 'var(--section-padding)'
      },
      '[class*=Hero-mediaWrap]': {
        paddingTop: 'var(--section-padding)',
        img: { borderRadius: 0 }
      }
    }
  }
];

export const heroTheme = (theme: Theme): ThemeOptions => ({
  components: {
    Hero: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default heroTheme;
